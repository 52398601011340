<template>
    <div>
        <datatable></datatable>
    </div>
</template>


<script>
import datatable from "./datatable.vue";

export default {
    components: {
        datatable
    },
    props: 
        [],
        data() {
            return {
                // 
            };
        },
    watch: {
        // 
    },
    mounted() {
        // 
    },
    computed: {
        // 
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },

        clickEdit(promotion) {
            this.$emit("selectPromotion", promotion);
            this.$emit("redirectTab", 0);
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>