<template>
    <div>
        <vs-divider style="width: 100%;"><vs-button size="small" class="vs-button-dark" disabled>Payment & Delivery</vs-button></vs-divider>
        <div class="vx-row w-full mb-2">
            <div class="vx-row vx-col w-full mb-3">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="browseParameter(23)" v-if="MonitoringType!='approval'">Payment Term</vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templatePaymentTerm.length>0 && promotion.line.length==0" @click="removeTemplate(23)"/>&nbsp;
                        <feather-icon title="Edit" icon="EyeIcon" svgClasses="w-4 h-4 text-primary stroke-current" v-if="templatePaymentTerm.length>0" @click="toogleViewTable(23)"/>
                    </span>
                    <vs-input class="w-full" :value="aggCode(templatePaymentTerm)" placeholder="Select Payment Term" disabled/>
                    <div v-if="templatePaymentTerm.length>0 && viewTable(23)">
                        <TableTemplateDetail ref="table_template_detail" :type="23" :template="templatePaymentTerm" :allowAction="MonitoringType != 'approval'" v-on:removeTemplateDetail="removeTemplateDetail"></TableTemplateDetail>
                    </div>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="browseParameter(24)" v-if="MonitoringType!='approval'">Delivery Type</vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateDeliveryType.length>0 && promotion.line.length==0" @click="removeTemplate(24)"/>&nbsp;
                        <feather-icon title="Edit" icon="EyeIcon" svgClasses="w-4 h-4 text-primary stroke-current" v-if="templateDeliveryType.length>0" @click="toogleViewTable(24)"/>
                    </span>
                    <vs-input class="w-full" :value="aggCode(templateDeliveryType)" placeholder="Select Delivery Type" disabled/>
                    <div v-if="templateDeliveryType.length>0 && viewTable(24)">
                        <TableTemplateDetail ref="table_template_detail" :type="24" :template="templateDeliveryType" :allowAction="MonitoringType != 'approval'" v-on:removeTemplateDetail="removeTemplateDetail"></TableTemplateDetail>
                    </div>
                </div>
            </div>
        </div>
        <vs-divider style="width: 100%;"><vs-button size="small" class="vs-button-dark" disabled>Applicable to Customer</vs-button></vs-divider>
        <div class="vx-row w-full mb-2">
            <div class="vx-row vx-col w-full mb-3">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="browseParameter(2)" v-if="MonitoringType!='approval'">Territory</vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateTerritory.length>0 && promotion.line.length==0" @click="removeTemplate(2)"/>&nbsp;
						            <feather-icon title="Edit" icon="EyeIcon" svgClasses="w-4 h-4 text-primary stroke-current" v-if="templateTerritory.length>0" @click="toogleViewTable(2)"/>
                    </span>
                    <vs-input class="w-full" :value="aggCode(templateTerritory)" placeholder="Select Territory" disabled/>
                    <div v-if="templateTerritory.length>0 && viewTable(2)">
                        <TableTemplateDetail ref="table_template_detail" :type="2" :template="templateTerritory" :allowAction="MonitoringType != 'approval'" v-on:removeTemplateDetail="removeTemplateDetail"></TableTemplateDetail>
                    </div>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="browseParameter(3)" v-if="MonitoringType!='approval'">Dist Channel</vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateCustomerCategory.length>0 && promotion.line.length==0" @click="removeTemplate(3)"/>&nbsp;
                        <feather-icon title="Edit" icon="EyeIcon" svgClasses="w-4 h-4 text-primary stroke-current" v-if="templateCustomerCategory.length>0" @click="toogleViewTable(3)"/>
                    </span>
                    <vs-input class="w-full" :value="aggCode(templateCustomerCategory)" placeholder="Select Dist Channel" disabled/>
					          <div v-if="templateCustomerCategory.length>0 && viewTable(3)">
                        <TableTemplateDetail ref="table_template_detail" :type="3" :template="templateCustomerCategory" :allowAction="MonitoringType != 'approval'" v-on:removeTemplateDetail="removeTemplateDetail"></TableTemplateDetail>
                    </div>
                </div>
            </div>
            <div class="vx-row vx-col w-full mb-3">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(9)]" @click="browseParameter(9)" v-if="MonitoringType!='approval'">Cust Group 1</vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateCustomerGroup1.length>0 && promotion.line.length==0" @click="removeTemplate(9)"/>&nbsp;
                        <feather-icon title="Edit" icon="EyeIcon" svgClasses="w-4 h-4 text-primary stroke-current" v-if="templateCustomerGroup1.length>0" @click="toogleViewTable(9)"/>
                    </span>
                    <vs-input class="w-full" :value="aggCode(templateCustomerGroup1)" placeholder="Select Cust Group 1" disabled/>
					          <div v-if="templateCustomerGroup1.length>0 && viewTable(9)">
                        <TableTemplateDetail ref="table_template_detail" :type="9" :template="templateCustomerGroup1" :allowAction="MonitoringType != 'approval'" v-on:removeTemplateDetail="removeTemplateDetail"></TableTemplateDetail>
                    </div>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(10)]" @click="browseParameter(10)" v-if="MonitoringType!='approval'">Cust Group 2</vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateCustomerGroup2.length>0 && promotion.line.length==0" @click="removeTemplate(10)"/>&nbsp;
                        <feather-icon title="Edit" icon="EyeIcon" svgClasses="w-4 h-4 text-primary stroke-current" v-if="templateCustomerGroup2.length>0" @click="toogleViewTable(10)"/>
                    </span>
                    <vs-input class="w-full" :value="aggCode(templateCustomerGroup2)" placeholder="Select Cust Group 2" disabled/>
					          <div v-if="templateCustomerGroup2.length>0 && viewTable(10)">
                        <TableTemplateDetail ref="table_template_detail" :type="10" :template="templateCustomerGroup2" :allowAction="MonitoringType != 'approval'" v-on:removeTemplateDetail="removeTemplateDetail"></TableTemplateDetail>
                    </div>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(11)]" @click="browseParameter(11)" v-if="MonitoringType!='approval'">Cust Group 3</vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateCustomerGroup3.length>0 && promotion.line.length==0" @click="removeTemplate(11)"/>&nbsp;
                        <feather-icon title="Edit" icon="EyeIcon" svgClasses="w-4 h-4 text-primary stroke-current" v-if="templateCustomerGroup3.length>0" @click="toogleViewTable(11)"/>
                    </span>
                    <vs-input class="w-full" :value="aggCode(templateCustomerGroup3)" placeholder="Select Cust Group 3" disabled/>
                    <div v-if="templateCustomerGroup3.length>0 && viewTable(11)">
                        <TableTemplateDetail ref="table_template_detail" :type="11" :template="templateCustomerGroup3" :allowAction="MonitoringType != 'approval'" v-on:removeTemplateDetail="removeTemplateDetail"></TableTemplateDetail>
                    </div>
                </div>
            </div>
            <div class="vx-row vx-col w-full mb-3">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(5)]" @click="browseParameter(5)" v-if="MonitoringType!='approval'">Pricing Group </vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templatePricingGroup.length>0 && promotion.line.length==0" @click="removeTemplate(5)"/>&nbsp;
                        <feather-icon title="Edit" icon="EyeIcon" svgClasses="w-4 h-4 text-primary stroke-current" v-if="templatePricingGroup.length>0" @click="toogleViewTable(5)"/>
                    </span>
                    <vs-input class="w-full" :value="aggCode(templatePricingGroup)" placeholder="Select Pricing Group" disabled/>
                    <div v-if="templatePricingGroup.length>0 && viewTable(5)">
                        <TableTemplateDetail ref="table_template_detail" :type="5" :template="templatePricingGroup" :allowAction="MonitoringType != 'approval'" v-on:removeTemplateDetail="removeTemplateDetail"></TableTemplateDetail>
                    </div>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(12)]" @click="browseParameter(12)" v-if="MonitoringType!='approval'">Cust Sold To </vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateCustomerSoldTo.length>0 && promotion.line.length==0" @click="removeTemplate(12)"/>&nbsp;
                        <feather-icon title="Edit" icon="EyeIcon" svgClasses="w-4 h-4 text-primary stroke-current" v-if="templateCustomerSoldTo.length>0" @click="toogleViewTable(12)"/>
                    </span>
                    <vs-input class="w-full" :value="aggCode(templateCustomerSoldTo)" placeholder="Select Cust Sold To" disabled/>
                    <div v-if="templateCustomerSoldTo.length>0 && viewTable(12)">
                        <TableTemplateDetail ref="table_template_detail" :type="12" :template="templateCustomerSoldTo" :allowAction="MonitoringType != 'approval'" v-on:removeTemplateDetail="removeTemplateDetail"></TableTemplateDetail>
                    </div>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(13)]" @click="browseParameter(13)" v-if="MonitoringType!='approval'">Cust Ship To </vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateCustomerShipTo.length>0 && promotion.line.length==0" @click="removeTemplate(13)"/>&nbsp;
                        <feather-icon title="Edit" icon="EyeIcon" svgClasses="w-4 h-4 text-primary stroke-current" v-if="templateCustomerShipTo.length>0" @click="toogleViewTable(13)"/>
                    </span>
                    <vs-input class="w-full" :value="aggCode(templateCustomerShipTo)" placeholder="Select Cust Ship To" disabled/>
                    <div v-if="templateCustomerShipTo.length>0 && viewTable(13)">
                        <TableTemplateDetail ref="table_template_detail" :type="13" :template="templateCustomerShipTo" :allowAction="MonitoringType != 'approval'" v-on:removeTemplateDetail="removeTemplateDetail"></TableTemplateDetail>
                    </div>
                </div>
            </div>
        </div>
        <vs-divider style="width: 100%;"><vs-button size="small" class="vs-button-dark" disabled>Type & Item Attribbutes</vs-button></vs-divider>
        <div class="vx-row w-full mb-2">
            <div class="vx-row vx-col w-full mb-3">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Type</span>
                    <multiselect
                            class="selectExample"
                            v-model="templateType"
                            :options="optionType"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder=" Type to search"
                            track-by="id"
                            label="id"
                            :disabled="this.promotion.line.length > 0 ? true : false"
                            :internal-search="false"
                        >
                        <template slot="singleLabel" slot-scope="dt">
                            <span class="option__desc">
                                <span class="option__title">{{ dt.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="dt">
                            <div class="option__desc">
                                <span class="option__title">{{ dt.option.name }}</span>
                            </div>
                        </template>
                    </multiselect>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(18)]" @click="browseParameter(18)" v-if="MonitoringType!='approval'">Category 1</vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateItemCategory1.id" @click="removeTemplateLocal(18)"/>
                    </span>
                    <vs-input class="w-full" v-model="templateItemCategory1.code" placeholder="Select Item Category 1" disabled/>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(19)]" @click="browseParameter(19)" v-if="MonitoringType!='approval'">Category 2</vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateItemCategory2.id" @click="removeTemplateLocal(19)"/>
                    </span>
                    <vs-input class="w-full" v-model="templateItemCategory2.code" placeholder="Select Item Category 2" disabled/>
                </div>
            </div>
            <div class="vx-row vx-col w-full mb-3">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(20)]" @click="browseParameter(20)" v-if="MonitoringType!='approval'">Material 1</vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateItemMaterial1.id" @click="removeTemplateLocal(20)"/>
                    </span>
                    <vs-input class="w-full" v-model="templateItemMaterial1.code" placeholder="Select Item Material 1" disabled/>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(21)]" @click="browseParameter(21)" v-if="MonitoringType!='approval'">Material 2</vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateItemMaterial2.id" @click="removeTemplateLocal(21)"/>
                    </span>
                    <vs-input class="w-full" v-model="templateItemMaterial2.code" placeholder="Select Item Material 2" disabled/>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(22)]" @click="browseParameter(22)" v-if="MonitoringType!='approval'">Material 3</vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateItemMaterial3.id" @click="removeTemplateLocal(22)"/>
                    </span>
                    <vs-input class="w-full" v-model="templateItemMaterial3.code" placeholder="Select Item Material 3" disabled/>
                </div>
            </div>
            <div class="vx-row vx-col w-full mb-3">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>
                        <vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(14)]" @click="browseParameter(14)" v-if="MonitoringType!='approval'">Division</vs-button>
                        <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateDivision.id" @click="removeTemplateLocal(14)"/>
                    </span>
                    <vs-input class="w-full" v-model="templateDivision.code" placeholder="Select division" disabled/>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span><vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(15)]" @click="browseParameter(15)" v-if="MonitoringType!='approval'">Combo</vs-button></span>
                    <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateCombo.id" @click="removeTemplateLocal(15)"/>
                    <vs-input class="w-full" v-model="templateCombo.code" placeholder="Select Combo" disabled/>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span><vs-button size="small" :class="['mr-3 mb-2 vs-button-' + colorTemplate(16)]" @click="browseParameter(16)" v-if="MonitoringType!='approval'">SKU</vs-button></span>
                    <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-4 h-4 text-danger stroke-current" v-if="templateItem.id" @click="removeTemplateLocal(16)"/>
                    <vs-input class="w-full" v-model="templateItem.full_text" placeholder="Select SKU" disabled/>
                </div>
            </div>
        </div>
        <vs-divider style="width: 100%;"><vs-button size="small" class="vs-button-dark" disabled>Applicable to Step</vs-button></vs-divider>
        <div class="vx-row w-full mb-2">
            <div class="vx-row vx-col sm:w-1/3 w-full">
                <div class="vx-col sm:w-1/4 w-full">
                    <span>From {{ this.text1}}</span>
                    <vs-input class="w-full" @keypress="isNumber($event)" v-model="templateFrom"/>
                </div>
                <div class="vx-col sm:w-1/4 w-full">
                    <span>To {{ this.text1}}</span>
                    <vs-input class="w-full" @keypress="isNumber($event)" v-model="templateTo"/>
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                    <span>Unit</span>
                    <multiselect
                        class="selectExample"
                        v-model="templateUnit"
                        :options="optionUnit"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder=" Type to search"
                        track-by="id"
                        label="name"
                        :disabled="templateItem.id != 0 && templateItem.id != undefined"
                    >
                    <template slot="singleLabel" slot-scope="dt">
                    <span class="option__desc">
                        <span class="option__title">{{ dt.option.name }}</span>
                    </span>
                    </template>

                    <template slot="option" slot-scope="dt">
                        <div class="option__desc">
                            <span class="option__title">{{ dt.option.name }}</span>
                        </div>
                        </template>
                  	</multiselect>
                </div>
            </div>
            <div class="vx-row vx-col sm:w-1/3 w-full">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Main Disc {{ this.text2 }}</span>
                    <vs-input class="w-full" @keypress="isNumber($event)" v-model="templateDisc" :disabled="promotion.type==2"/>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Add. Disc {{ this.text2 }}</span>
                    <vs-input class="w-full" @keypress="isNumber($event)" v-model="templateDiscAdd" :disabled="promotion.type==2"/>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Cap Disc {{ this.text2 }}</span>
                    <vs-input class="w-full" @keypress="isNumber($event)" v-model="templateDiscCap"/>
                </div>
            </div>
            <div class="vx-row vx-col sm:w-1/3 w-full">
                <div class="vx-col sm:w-1/2 w-full">
                    <span>Per</span>
                    <vs-input class="w-full" @keypress="isNumber($event)" v-model="templatePer" :disabled="templateType.id==1"/>
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                    <span>Per Unit</span>
                    <multiselect
                            class="selectExample"
                            v-model="templatePerUnit"
                            :options="optionPerUnit"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder=" Type to search"
                            track-by="id"
                            label="name"
                            :disabled="templateType.id==1 || promotion.base ==2 "
                        >
                        <template slot="singleLabel" slot-scope="dt">
                            <span class="option__desc">
                                <span class="option__title">{{ dt.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="dt">
                            <div class="option__desc">
                                <span class="option__title">{{ dt.option.name }}</span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
        </div>
        <vs-divider style="width: 100%;">
            <!-- <vs-button size="small" class="vs-button-danger mr-3 mb-2" v-on:click="clearTemplate()">Clear</vs-button> -->
            <vs-button size="small" class="vs-button-primary mr-3 mb-2" v-on:click="submitTemplate(false)" v-if="MonitoringType!='approval'">Add</vs-button>
            <!-- <vs-button size="small" class="vs-button-success mr-3 mb-2" v-on:click="submitTemplate(true)">Add & Clear</vs-button> -->
        </vs-divider>
    </div>
</template>
<script>
    import Datepicker from "vuejs-datepicker";
    import TableTemplateDetail from "../component/table_template_detail.vue";
    export default {
        props: ["optionType", "promotion", "templateTerritory", "templateCustomerCategory", "templateCustomerGroup1", "templateCustomerGroup2", "templateCustomerGroup3", "templatePricingGroup", "templateCustomerSoldTo", "templateCustomerShipTo", "templatePaymentTerm", "templateDeliveryType","MonitoringType"],
        components: {
            Datepicker,
            TableTemplateDetail
        },
        data: () => ({
            text1: "Qty",
            text2: "",

            templateFlushOut: 0,
            templateType: {},
            templateValidFrom:"",
            templateValidTo:"",
            templateItemCategory1:{},
            templateItemCategory2:{},
            templateItemMaterial1:{},
            templateItemMaterial2:{},
            templateItemMaterial3:{},
            templateDivision:{},
            templateCombo:{},
            templateItem:{},
            templateFrom:1,
            templateTo:99999999,
            templateDisc:null,
            templateDiscAdd:null,
            templateDiscCap:null,
            templatePer:null,
            templatePerUnit:{},
            templateUnit:{},

            // templateTerritory : [],
            // templateCustomerCategory : [],
            // templateCustomerGroup : [],
            // templateCustomerGroup1 : [],
            // templateCustomerGroup2 : [],
            // templateCustomerGroup3 : [],
            // templatePricingGroup : [],
            // templateCustomerSoldTo : [],
            // templateCustomerShipTo : [],

            optionDivision: [{}],
            optionCombo: [{}],
            optionItem: [{}],
            optionUnit: [{}],
            optionPerUnit: [{}],
            optionTerritory: [{}],
            optionCustomerCategory: [{}],
            optionCustomerGroup: [{}],
            optionCustomerGroup1: [{}],
            optionCustomerGroup2: [{}],
            optionCustomerGroup3: [{}],
            optionPricingGroup: [{}],
            optionCustomerSoldto: [{}],
            optionCustomerShipTo: [{}],

            viewDetail: [
                {"id": 23, "status": false},
                {"id": 24, "status": false},
                {"id": 2, "status": false},
                {"id": 3, "status": false},
                {"id": 5, "status": false},
                {"id": 9, "status": false},
                {"id": 10, "status": false},
                {"id": 11, "status": false},
                {"id": 12, "status": false},
                {"id": 13, "status": false},
            ]
        }),
        mounted() {
            this.getOptionUnit()
        },
        computed: {
            watchedProperties() {
                return `${this.promotion.type}||${this.promotion.base}||${this.templateType.id}`;
            },
            watchedProperties2() {
                return `${this.templateItem.id}||${this.templatePer}`;
            },
        },
        watch: {
            watchedProperties: function() {
              console.log("this.promotion.type", this.promotion.type)
                if(this.promotion.type == 2) {
                  this.templateDisc = null
                  this.templateDiscAdd = null
                }

                // jika percentage, maka tidak bisa berlaku kelipatan
                if(this.templateType.id==1) {
                  this.templatePer = null
                  this.templatePerUnit = {}
                }

                // jika promo based on "Value", kelipatan tidak perlu pilih unit
                if(this.promotion.base==2) {
                  this.templatePerUnit = {}
                }

                this.updateText()
            },
            watchedProperties2: function(newVal, oldVal) {
                if((this.templateItem && this.templateItem.id) && this.templatePer > 0) {
                    let newData = newVal.split("||")
                    let newItemID = newData[0]
                    let newQty = newData[1]
                    let oldData = oldVal.split("||")
                    let oldItemID = oldData[0]
                    let oldQty = oldData[1]
                    if(newItemID != oldItemID || (oldQty == "null" || oldQty == 0)) {
                        this.templatePerUnit = {}
                        this.getOptionPerUnit(this.templateItem.item_id)
                    }
                } else {
                    this.optionPerUnit = [{}]
                    this.templatePerUnit = {}
                }

                for (var i=0; i < this.optionUnit.length; i++) {
                    if(this.optionUnit[i].id == this.templateItem.unit_id) {
                        this.templateUnit = this.optionUnit[i]
                        break
                    }
                }
            }
        },
        methods: {
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            updateText() {
                switch (this.promotion.base) {
                    case "1":
                        this.text1 = "Quantity"
                        break;
                    case "2":
                        this.text1 = "Value"
                        this.templatePer = null
                        this.templatePerUnit = null
                        break;
                    case "3":
                        this.text1 = "Weight"
                        break;

                    default:
                        break;
                }

                switch (this.templateType.id) {
                    case 1:
                        this.text2 = "%"
                        break;
                    case 2:
                        this.text2 = "IDR"
                        break;

                    default:
                        break;
                }
            },
            aggCode(template) {
                let agg = ""
                for (var i=0; i < template.length; i++) {
                    if(i==0) {
                        agg += template[i].code
                    } else {
                        agg += ", "+template[i].code
                    }
                }
                return agg
            },
            toogleViewTable(type) {
                let vd = this.viewDetail.filter(function(opt){
                    if(opt.id == type) {
                        return true
                    }
                })[0]

                vd.status = !vd.status
            },
            viewTable(type) {
                return this.viewDetail.filter(function(opt){
                    if(opt.id == type) {
                        return true
                    }
                })[0].status
            },
            browseParameter(type) {
                let checkTemplate = this.checkTemplate(type)
                let check = checkTemplate[0]
                let message = checkTemplate[1]
                if(!check) {
                    this.$vs.notify({
                        title: "Cant select this parameter",
                        text: "Other parameter already selected <br>["+message+"]",
                        color: "warning",
                        position: "top-right",
                    });
                } else {
                    let params ={}

                    if (this.templateCombo.code&&this.templateCombo.code!="") {
                        params.code_combo = this.templateCombo.code
                    }

                  this.$emit("browseParameter", type,0,params);
                }
            },
            checkOtherParam(type) {
                let otherParam = []
                switch(type) {
                    case 12: // soldto
                        otherParam.push({"name": "dist channel", "data": this.templateCustomerCategory})
                        otherParam.push({"name": "cust group 1", "data": this.templateCustomerGroup1})
                        otherParam.push({"name": "cust group 2", "data": this.templateCustomerGroup2})
                        otherParam.push({"name": "cust group 3", "data": this.templateCustomerGroup3})
                        otherParam.push({"name": "pricing group", "data": this.templatePricingGroup})
                        otherParam.push({"name": "shipto", "data": this.templateCustomerShipTo})
                        break;
                    case 13: // shipto
                        otherParam.push({"name": "dist channel", "data": this.templateCustomerCategory})
                        otherParam.push({"name": "cust group 1", "data": this.templateCustomerGroup1})
                        otherParam.push({"name": "cust group 2", "data": this.templateCustomerGroup2})
                        otherParam.push({"name": "cust group 3", "data": this.templateCustomerGroup3})
                        otherParam.push({"name": "pricing group", "data": this.templatePricingGroup})
                        otherParam.push({"name": "soldto", "data": this.templateCustomerSoldTo})
                        break;
                    case 3:
                    case 9:
                    case 10:
                    case 11:
                    case 5:
                        otherParam.push({"name": "shipto", "data": this.templateCustomerShipTo})
                        otherParam.push({"name": "soldto", "data": this.templateCustomerSoldTo})
                        break;
                    case 15:
                    case 16:
                        otherParam.push({"name": "item cat 1", "data": this.templateItemCategory1})
                        otherParam.push({"name": "item cat 2", "data": this.templateItemCategory2})
                        otherParam.push({"name": "item mat 1", "data": this.templateItemMaterial1})
                        otherParam.push({"name": "item mat 2", "data": this.templateItemMaterial2})
                        otherParam.push({"name": "item mat 3", "data": this.templateItemMaterial3})
                        // otherParam.push({"name": "item combo", "data": this.templateCombo})
                        break;
                    case 18:
                    case 19:
                    case 20:
                    case 21:
                    case 22:
                        otherParam.push({"name": "item sku", "data": this.templateItem})
                        break;
                }

                return otherParam
            },
            checkTemplate(type) {
                let check = true
                let messages = []
                let otherParam = this.checkOtherParam(type)

                for (var i=0; i < otherParam.length; i++) {
                    // jika array
                    if(Array.isArray(otherParam[i].data)) {
                        if(otherParam[i].data.length > 0) {
                            check = false
                            messages.push(otherParam[i].name)
                        }
                    } else { // jika bukan array
                        if(otherParam[i].data && otherParam[i].data.code != "" && otherParam[i].data.code != undefined) {
                            // console.log("otherParam[i].data.code", otherParam[i].data.code)
                            check = false
                            messages.push(otherParam[i].name)
                        }
                    }
                }

                let message = messages.join(", ")
                return [check, message]
            },
            colorTemplate(type) {
                let checkTemplate =this.checkTemplate(type)
                let check = checkTemplate[0]
                if(!check) {
                  return "danger"
                }
                return "dark"
            },
            removeTemplate(type) {
                this.$emit("removeTemplate", type);
            },
            removeTemplateDetail(option) {
                this.$emit("removeTemplateDetail", option);
            },
            removeTemplateLocal(type) {
                switch(type) {
                    case 14:
                        this.templateDivision = {}
                        break;
                    case 15:
                        this.templateCombo = {}
                        break;
                    case 16:
                        this.templateItem = {}
                        break;
                    case 18:
                      this.templateItemCategory1 = {}
                      break;
                    case 19:
                      this.templateItemCategory2 = {}
                      break;
                    case 20:
                      this.templateItemMaterial1 = {}
                      break;
                    case 21:
                      this.templateItemMaterial2 = {}
                      break;
                    case 22:
                      this.templateItemMaterial3 = {}
                      break;
                }
            },
            checkDuplicate(arr, data) {
              let checkDuplicate = false
              checkDuplicate = arr.some(element => {
                  if (element.id == data.id) {
                      return true;
                  }
              });

              return checkDuplicate
            },
            setTemplate(type, data) {
                // 2: territory
                // 3: customer_category (dist channel)
                // 5: pricing group (cust group 4)
                // 9: customer group 1
                // 10: customer group 2
                // 11: customer group 3
                // 12: customer sold to (customers)
                // 13: customer ship to (customer address)
                // 14: division
                // 15: combo
                // 16: item (sku + hu)
                let typeString = ""
                let duplicate = false
                switch(type) {
                    case 2:
                        duplicate = this.checkDuplicate(this.templateTerritory, data)
                        if(!duplicate) {
                          this.templateTerritory.push(data)
                          typeString = "Territory"
                        }
                        break;
                    case 3:
                        duplicate = this.checkDuplicate(this.templateCustomerCategory, data)
                        if(!duplicate) {
                          this.templateCustomerCategory.push(data)
                          typeString = "Dist Channel"
                        }
                        break;
                    case 5:
                        duplicate = this.checkDuplicate(this.templatePricingGroup, data)
                        if(!duplicate) {
                          this.templatePricingGroup.push(data)
                          typeString = "Pricing Group"
                        }
                        break;
                    case 9:
                        duplicate = this.checkDuplicate(this.templateCustomerGroup1, data)
                        if(!duplicate) {
                          this.templateCustomerGroup1.push(data)
                          typeString = "Cust Group 1"
                        }
                        break;
                    case 10:
                        duplicate = this.checkDuplicate(this.templateCustomerGroup2, data)
                        if(!duplicate) {
                          this.templateCustomerGroup2.push(data)
                          typeString = "Cust Group 2"
                        }
                        break;
                    case 11:
                        duplicate = this.checkDuplicate(this.templateCustomerGroup3, data)
                        if(!duplicate) {
                          this.templateCustomerGroup3.push(data)
                          typeString = "Cust Group 3"
                        }
                        break;
                    case 12:
                        duplicate = this.checkDuplicate(this.templateCustomerSoldTo, data)
                        if(!duplicate) {
                          this.templateCustomerSoldTo.push(data)
                          typeString = "Cust Sold To"
                        }
                        break;
                    case 13:
                        duplicate = this.checkDuplicate(this.templateCustomerShipTo, data)
                        if(!duplicate) {
                          this.templateCustomerShipTo.push(data)
                          typeString = "Cust Ship To"
                        }
                        break;
                    case 14:
                        this.templateDivision = data
                        typeString = "Division"
                        break;
                    case 15:
                        this.templateCombo = data
                        typeString = "Combo"
                        break;
                    case 16:
                        this.templateItem = data
                        this.templateItem.full_text = data.code +" "+ data.name +", "+ data.unit
                        typeString = "SKU"
                        break;
                    case 18:
                        this.templateItemCategory1 = data
                        typeString = "Category 1"
                        break;
                    case 19:
                        this.templateItemCategory2 = data
                        typeString = "Category 2"
                        break;
                    case 20:
                        this.templateItemMaterial1 = data
                        typeString = "Material 1"
                        break;
                    case 21:
                        this.templateItemMaterial2 = data
                        typeString = "Material 2"
                        break;
                    case 22:
                        this.templateItemMaterial3 = data
                        typeString = "Material 3"
                        break;
					          case 23:
                        duplicate = this.checkDuplicate(this.templatePaymentTerm, data)
                        if(!duplicate) {
                          this.templatePaymentTerm.push(data)
                          typeString = "Payment Term"
                        }
                        break;
					          case 24:
                        duplicate = this.checkDuplicate(this.templateDeliveryType, data)
                        if(!duplicate) {
                          this.templateDeliveryType.push(data)
                          typeString = "Delivery Type"
                        }
                        break;
                }
                if(data.id==null) {
                    return true
                }

                if(!duplicate) {
                  this.$vs.notify({
                      title: "Selected "+typeString,
                      text: data.code,
                      color: "primary",
                      position: "top-right",
                  });
                } else {
                  this.$vs.notify({
                      title: "Already selected "+typeString,
                      text: data.code,
                      color: "warning",
                      position: "top-right",
                  });
                }
            },
            setTemplateType(id) {
                this.templateType = this.optionType.filter(function(opt){
                    if(opt.id == id) {
                        return true
                    }
                })[0]
            },
            submitTemplate(isReset) {
                let combination = ""
                combination = this.templateDivision.code
                combination += "-"+this.templateItemMaterial1.code+"-"+this.templateItemMaterial2.code+"-"+this.templateItemMaterial3.code
                combination += "-"+this.templateItemCategory1.code+"-"+this.templateItemCategory2.code
                combination += "-"+this.templateCombo.code+"-"+this.templateItem.code+"-"+this.templateItem.unit
                this.$emit("addLine", {
                    "type": this.templateType,
                    "valid_from": this.templateValidFrom,
                    "valid_to": this.templateValidTo,
                    "division": this.templateDivision,
                    "category_1": this.templateItemCategory1,
                    "category_2": this.templateItemCategory2,
                    "material_1": this.templateItemMaterial1,
                    "material_2": this.templateItemMaterial2,
                    "material_3": this.templateItemMaterial3,
                    "combo": this.templateCombo,
                    "item": this.templateItem,
                    "unit_id": this.templateUnit.id,
                    "item_unit": this.templateUnit.name,
                    "from": parseInt(this.templateFrom),
                    "to": parseInt(this.templateTo),
                    "disc": parseFloat(this.templateDisc),
                    "disc_add": parseFloat(this.templateDiscAdd),
                    "disc_cap": parseFloat(this.templateDiscCap),
                    "disc_per": parseInt(this.templatePer),
                    "disc_per_unit": this.templatePerUnit,
                    "free_item": [],
                    "mandatory": true,
                    "multiply": true,
                    "combination": combination,
                    "sort": combination+"-"+parseInt(this.templateFrom)+"-"+parseInt(this.templateTo),
                });

                if(isReset) {
                    this.resetTemplate()
                }
            },
            resetTemplate() {
                this.templateType = {}
                this.templateValidFrom = {}
                this.templateValidTo = {}
                this.templateItemCategory1 = {}
                this.templateItemCategory2 = {}
                this.templateItemMaterial1 = {}
                this.templateItemMaterial2 = {}
                this.templateItemMaterial3 = {}
                this.templateDivision = {}
                this.templateCombo = {}
                this.templateItem = {}
                this.templateTerritory = []
                this.templateCustomerCategory = []
                this.templateCustomerGroup1 = []
                this.templateCustomerGroup2 = []
                this.templateCustomerGroup3 = []
                this.templatePricingGroup = []
                this.templateCustomerSoldTo = []
                this.templateCustomerShipTo = []
            },
            getOptionPerUnit(itemID) {
                this.$vs.loading();
                let url = "/api/v1/master/unit-by-item/"+itemID

                this.$http.get(url).then(resp => {
                    if (resp.code == 200) {
                        this.optionPerUnit = [];
                        for (var i=0; i < resp.data.length; i++) {
                            this.optionPerUnit.push({
                                id: resp.data[i].ID,
                                name: resp.data[i].Name,
                            })
                        }
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                    this.$vs.loading.close();
                });
            },
            getOptionUnit() {
                this.$vs.loading();
                let url = "/api/v1/master/list-unit"

                this.$http.get(url).then(resp => {
                    if (resp.code == 200) {
                        this.optionUnit = [];
                        for (var i=0; i < resp.data.records.length; i++) {
                            this.optionUnit.push({
                                id: resp.data.records[i].id,
                                name: resp.data.records[i].name,
                            })
                        }
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                    this.$vs.loading.close();
                });
            }
        },
    }
</script>
