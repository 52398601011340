<template>
    <div>
        <vs-table :data="dataParameter" v-if="popupParameterType==1"> 
            <template slot="thead">
                <vs-th>Code</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Address</vs-th>
                <vs-th>Description</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :key="idx" v-for="(dt, idx) in data">
                    <vs-td>{{dt.Code}}</vs-td>
                    <vs-td>{{dt.Name}}</vs-td>
                    <vs-td>{{dt.Address}}</vs-td>
                    <vs-td>{{dt.Description}}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-table :data="dataParameter" v-if="popupParameterType==2">
            <template slot="thead">
                <vs-th>Code</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Description</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :key="idx" v-for="(dt, idx) in data">
                    <vs-td>{{dt.code}}</vs-td>
                    <vs-td>{{dt.name}}</vs-td>
                    <vs-td>{{dt.description}}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-table :data="dataParameter" v-if="popupParameterType==3">
            <template slot="thead">
                <vs-th>Name</vs-th>
                <vs-th>Classification</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :key="idx" v-for="(dt, idx) in data">
                    <vs-td>{{dt.name}}</vs-td>
                    <vs-td>{{dt.classification}}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-table :data="dataParameter" v-if="popupParameterType==4">
            <template slot="thead">
                <vs-th>Code</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Description</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :key="idx" v-for="(dt, idx) in data">
                    <vs-td>{{dt.code}}</vs-td>
                    <vs-td>{{dt.name}}</vs-td>
                    <vs-td>{{dt.description}}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-table :data="dataParameter" v-if="popupParameterType==5">
            <template slot="thead">
                <vs-th>Code</vs-th>
                <vs-th>Name</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :key="idx" v-for="(dt, idx) in data">
                    <vs-td>{{dt.code}}</vs-td>
                    <vs-td>{{dt.name}}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-table :data="dataParameter" v-if="popupParameterType==6">
            <template slot="thead">
                <vs-th>Name</vs-th>
                <vs-th>Due (days)</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :key="idx" v-for="(dt, idx) in data">
                    <vs-td>{{dt.name}}</vs-td>
                    <vs-td>{{dt.due_invoice_period}}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-table :data="dataParameter" v-if="popupParameterType==7">
            <template slot="thead">
                <vs-th>Name</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :key="idx" v-for="(dt, idx) in data">
                    <vs-td>{{dt.Name}}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-table :data="dataParameter" v-if="popupParameterType==8">
            <template slot="thead">
                <vs-th>Code</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Description</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :key="idx" v-for="(dt, idx) in data">
                    <vs-td>{{dt.Code}}</vs-td>
                    <vs-td>{{dt.Name}}</vs-td>
                    <vs-td>{{dt.Description}}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </div>
</template>

<script>
    export default {
        props: ["popupParameterType", "dataParameter"],
        components: {
            // 
        },
        mounted(){
            // 
        },
        computed: {
            // 
        },
        watch: {
            // 
        },
        data: () => ({
            // popupParameterType => 1: sales area (terriory area); 2: territory; 3: distribution channel (customer category); 4: customer group; 5: pricing (pricing group); 6: payment term; 7: delivery type;
        }),
        methods: {
            // 
        },
    };
</script>