<template>
    <div ref="form_set">
        <div class="vx-row mb-6 ml-4 mr-4">
            <div class="vx-col sm:w-1/5 w-full">
                <span>Code</span>
            </div>
            <div class="vx-col sm:w-1/5 w-full">
                <vs-input class="w-full" name="code" v-model.lazy="data.Code" readonly/>
            </div>
            <div class="vx-col sm:w-1/5 w-full">
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('currency code')"
                    >{{ errors.first('currency code') }}
                </span>
            </div>
            <div class="vx-col sm:w-1/5 w-full flex">
                Active&nbsp;
                <vs-switch name="is active" v-model="data.IsActive"/>
            </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
            <div class="vx-col sm:w-1/5 w-full">
                <span>Name</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <vs-input v-validate="'required'" class="w-full" name="name" v-model.lazy="data.Name"/>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('name')"
                    >{{ errors.first('name') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
            <div class="vx-col sm:w-1/5 w-full">
                <span>Description</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <vs-input v-validate="'required'" class="w-full" name="description" v-model.lazy="data.Description"/>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('description')"
                    >{{ errors.first('description') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
            <div class="vx-col sm:w-1/5 w-full">
                <span>Valid From</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <datepicker :disabled="read" v-validate="'required'" name="valid from" :inline="false" v-model.lazy="data.ValidFrom" placeholder="Select Date"></datepicker>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('valid from')"
                    >{{ errors.first('valid from') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
            <div class="vx-col sm:w-1/5 w-full">
                <span>Valid To</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <datepicker :disabled="read" v-validate="'required'" name="valid to" :inline="false" v-model.lazy="data.ValidTo" placeholder="Select Date"></datepicker>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('valid to')"
                    >{{ errors.first('valid to') }}
                </span>
            </div>
        </div>
        <vs-divider style="width: 100%;"><vs-button size="small" class="mr-3 mb-2 vs-button-dark">Promotion Matrix</vs-button></vs-divider>
        <div class="vx-row mb-6 ml-4 mr-4">
            <div class="vx-col sm:w-5/5 w-full mb-2" v-for="(item, indextr) in items" :key="indextr">
                <div class="vx-row mb-2">
                    <div class="vx-col w-1/5" style="display: flex">
                        <vs-input label="Code" class="w-full" :value="item.Code" readonly/>
                    </div>
                    <div class="vx-col w-2/5" style="display: flex">
                        <vs-input label="Name" class="w-full" :value="item.Name" readonly/>
                    </div>
                    <div class="vx-col w-2/5" style="display: flex">
                        <vs-input @keypress="isNumber($event)" label="Priority" :value="item.Priority" @change="item.Priority = $event.target.value; itemSort();" class="w-full" type="number" min="0" name="Priority"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
            <div class="vx-col">
                <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
            </div>
        </div>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    props: ["selectedSet"],
    created(){
        // console.log("created!")
    },
    mounted(){
        // 
    },
    watch: {
        selectedSet: function() {
            this.data = this.selectedSet
            this.selected_items = this.data.Line
            this.getPromotionMatrix()
        },
    },
    computed: {
        // 
    },
    data: () => ({
        read: false,
        items: [],
        selected_items: [],
        data: {
            Code: "",
            Name: "",
            Description: "",
            ValidFrom: "",
            ValidTo: "",
            IsActive: false,
        },
    }),
    methods: {
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        itemSort() {
            this.items.sort(function(a, b) {
                if(a.Priority == 0) {
                    return 1;
                } else if(b.Priority == 0) {
                    return -1;
                }
                return a.Priority - b.Priority;
            });
        },
        handleBack() {
            this.$router.push("/master/pricing-management-v2");
        },
        getCode() {
            this.$vs.loading();
            this.$http
            .get("/api/v1/master/engine-price/code")
            .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.data.code = resp.data
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
                
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        getPromotionMatrix() {
            var _this = this
            this.$vs.loading();
            this.$http.get("/api/v2/promotion-matrix", {
                params: {
                    is_active: true
                }
            }).then(resp => {
                _this.items = []
                if (resp.status == "success") {
                    for (var i = 0; i < resp.data.PromotionMatrixes.length; i++) {
                        let element = resp.data.PromotionMatrixes[i]
                        _this.items.push({
                            ID: element.DefaultAttribute.ID,
                            Code: element.Code,
                            Name: element.Name,
                            Priority:  _this.findLinePriorityByID(element.ID),
                        })
                    }
                    this.itemSort()
                    this.$vs.loading.close();
                }
            });
        },
        findLinePriorityByID(ID) {
            if(this.selected_items) {
                for (var i = 0; i < this.selected_items.length; i++) {
                    let item = this.selected_items[i]
                    if(item.ID == ID) {
                        return item.Priority
                    }
                }
            }
            return 0
        },
        setParam() {
            let lines = []
            this.items.forEach(function(el, idx){
                if(el.Priority > 0) {
                    let temp = {
                        v2_promotion_matrix_id: el.ID,
                        priority: parseInt(el.Priority)
                    }
                    lines.push(temp)
                }
            })

            let params = {
                code: this.data.Code,
                name: this.data.Name,
                description: this.data.Description,
                valid_from: this.data.ValidFrom,
                valid_to: this.data.ValidTo,
                is_active: this.data.IsActive,
                line: lines
            }
            return params
        },
        handleSubmit() {
            this.setParam()
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.$vs.loading();
                    this.$http
                        .post("/api/v2/promotion-set/store", this.setParam())
                        .then(resp => {
                        this.$vs.loading.close();
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: "New Price Set Created",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle"
                            });
                            this.$emit("closeForm");
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle"
                            });
                        }
                    })
                    .catch(error => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                }
            });
        },
    }
}

</script>