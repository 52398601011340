<style scoped>
    span.matrix_param {
        cursor: pointer;
    }
    span.matrix_param:hover {
        color: var(--hovercolor);
    }
</style>

<template>
    <div>
        <!-- main page -->
        <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/1 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>                
                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                    <span>{{item}}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="mx-auto">
                        <vs-button
                            class="mt-2"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-plus"
                            @click="clickCreate()"
                        >Create</vs-button>
                    </div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Matrix Code, Name, or Description" />
                        </div>
                    </div>
                </div>

                <vs-table :data="dataSet" stripe border>
                    <template slot="thead">
                        <vs-th>Set</vs-th>
                        <vs-th>Active</vs-th>
                        <vs-th>Valid</vs-th>
                        <vs-th>Detail</vs-th>
                        <vs-th>Action</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <vs-tr :key="idx" v-for="(dt, idx) in data">
                            <vs-td style="width:20%">
                                Code : {{dt.Code}}<br>
                                Name : {{dt.Name}}<br>
                                Description : {{dt.Description}}
                            </vs-td>
                            <vs-td>
                            {{ dt.IsActive ? 'True' : 'False' }}
                            </vs-td>
                            <vs-td>
                                <div class="vx-row ml-0">
                                    <span class="w-1/5">From</span>
                                    <span class="w-4/5">: {{ dateFormat(dt.ValidFrom) }}</span>
                                </div>
                                <div class="vx-row ml-0">
                                    <span class="w-1/5">To</span>
                                    <span class="w-4/5">: {{ dateFormat(dt.ValidTo) }}</span>
                                </div>
                            </vs-td>
                            <vs-td>
                                <table style="border-collapse: collapse;">
                                    <tr>
                                        <th>Matrix Code</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Priority</th>
                                    </tr>
                                    <tr :key="idxc" v-for="(line, idxc) in dt.Line">
                                        <td>
                                            {{line.Matrix.Code}}
                                        </td>
                                        <td>
                                            {{line.Matrix.Name}}
                                        </td>
                                        <td>
                                            {{line.Matrix.Description}}
                                        </td>
                                        <td>
                                            {{line.Priority}}
                                        </td>
                                    </tr>
                                </table>
                            </vs-td>
                            <vs-td>
                                <feather-icon title="Edit" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickEdit(dt)"/>
                                <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click="clickDelete(dt)"/>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
        
                <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
            </div>
        </div>
        <vs-popup fullscreen title="Form Promotion Set" :active.sync="popupForm">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <formSet :selectedSet="selectedSet" v-on:closeForm="closeForm"></formSet>
                </div>
            </div>
        </vs-popup>
    </div>
</template>


<script>
import formSet from "./form_set.vue";
// import moment from 'moment'

export default {
    components: {
        formSet,
    },
    props: 
        ["progress"],
        mounted() {
            // this.selectedSet = this.dataSet
        },
        data() {
            return {
                selectedSet: {},
                dataSet: [],

                limits: [10, 25, 50, 100, "All"],
                limitShow: 10,
                drawData: 0,
                isActive: 1,
                search: "",
                recordsTotal: 0,
                maxPage: 2,
                dataEnd: 0,

                popupForm: false,
                popupParameter: false,
                popupParameterType: 0,

                matrixParameterStyle: {
                    '--cursor': 'pointer',
                    '--hovercolor': 'blue',
                },
            };
        },
    watch: {
        watchedProperties: function() {
            this.getData();
        }
    },
    computed: {
        watchedProperties() {
            return `${this.territory}||${this.salesChannel}||${this.customerCategory}||${this.search}`;
        },
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                this.page(page);
            }
        }
    },
    methods: {
        dateFormat(date) {
            if (date) {
                // return moment(String(date)).utcOffset(0).format('DD/MM/YYYY hh:mm')
                let dt = new Date(date)
                let d = dt.getDate()
                let m = dt.getMonth()+1
                let y = dt.getFullYear()
                return d+"/"+m+"/"+y
            }
        },

        scrollToTop() {
            window.scrollTo(0,0);
        },

        clickEdit(data) {
            this.selectedSet = data
            this.popupParameter = false
            this.popupParameterType = 0
            this.popupForm = true
        },

        clickCreate() {
            this.selectedSet = {}
            this.popupParameter = false
            this.popupParameterType = 0
            this.popupForm = true
        },

        closeForm() {
            this.selectedSet = {}
            this.popupParameter = false
            this.popupParameterType = 0
            this.popupForm = false
            this.getData()
        },

        clickDelete(data) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "You are about to cancel '"+data.Code+"' ?",
                accept: this.actionDelete,
                parameters: data
            });
        },

        actionDelete(data) {
            this.$vs.loading();
            this.$http
                .post("api/v2/promotion-set/cancel", {
                    id: data.ID,
                    code: data.Code,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Promotion Matrix successfully cancelled",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    }
            });
        },

        page(page) {
            this.getData(page);
            return page;
        },

        changeLimit(limit) {
            this.limitShow = limit;
            this.getData(this.isActive);
        },

        getData(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            var parameter = {
                search: this.search,
                limit: this.limitShow,
                offset: this.limitShow * (page - 1)
            };

            this.$http.get("/api/v2/promotion-set", {
                params: parameter
            }).then(resp => {
                if (resp.code == 200) {
                    this.drawData = this.drawData + 1;
                    this.dataSet = resp.data.PromotionSets;
                    this.recordsTotal = resp.data.RecordsTotal;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.ResultsTotal;
                    this.$vs.loading.close();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            });
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>