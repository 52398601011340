<template>
    <div>
        <!-- main page -->
        <vs-tabs ref="sub-tabs" alignment="center">
            <vs-tab label="Draft" v-if="MonitoringType !='approval'">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'draft'" :MonitoringType="MonitoringType" v-on:clickEdit="clickEdit"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Pending">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'pending'" :MonitoringType="MonitoringType" v-on:clickEdit="clickEdit" @clickDetail="clickDetail"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Approved">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'approve'" :MonitoringType="MonitoringType" v-on:clickEdit="clickEdit" @clickDetail="clickDetail"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Active" v-if="MonitoringType !='approval'">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'active'" :MonitoringType="MonitoringType" v-on:clickEdit="clickEdit" @clickDetail="clickDetail"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Released" v-if="MonitoringType !='approval'">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'released'" :MonitoringType="MonitoringType" v-on:clickEdit="clickEdit" @clickDetail="clickDetail"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Expired" v-if="MonitoringType !='approval'">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'expired'" :MonitoringType="MonitoringType" v-on:clickEdit="clickEdit" @clickDetail="clickDetail"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Cancelled" v-if="MonitoringType !='approval'">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'cancelled'" :MonitoringType="MonitoringType" v-on:clickEdit="clickEdit" @clickDetail="clickDetail"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Rejected">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'reject'" :MonitoringType="MonitoringType" v-on:clickEdit="clickEdit" @clickDetail="clickDetail"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Inquiry">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'inquiry'" :MonitoringType="MonitoringType" v-on:clickEdit="clickEdit" @clickDetail="clickDetail"></datatable>
                </div>
            </vs-tab>
        </vs-tabs>
    </div>
</template>


<script>
import datatable from "./datatable.vue";

export default {
    components: {
        datatable
    },
    props: 
        ["territory", "salesChannel", "customerCategory","MonitoringType"],
        data() {
            return {
                // 
            };
        },
    watch: {
        // 
    },
    mounted() {
        // 
    },
    computed: {
        // 
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },

        clickEdit(promotion) {
            this.$emit("selectPromotion", promotion);
            this.$emit("redirectTab", 0);
        },
        clickDetail(promotion) {
            console.log("clickDetail2")
            this.$emit("clickDetail", promotion);
            this.$emit("redirectTab", 0);
        },
        clickSendToApproval(promotion) {
            this.$emit("selectPromotion", promotion);
            this.$emit("redirectTab", 0);
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>