<template>
    <div>
        <br>
        <table style="text-align: center; vertical-align: middle;">
            <tr>
                <th style="border: 1px solid; border-collapse: collapse;">Code</th>
                <th style="border: 1px solid; border-collapse: collapse;" v-if="allowAction">Action</th>
            </tr>
            <tr :key="idx" v-for="(dt, idx) in template">
                <td style="border: 1px solid; border-collapse: collapse;">{{ dt.code }}</td>
                <vs-td style="border: 1px solid; border-collapse: collapse;" v-if="allowAction">
                    <feather-icon title="Delete" icon="TrashIcon" svgClasses="h-4 text-danger" @click="removeTemplateDetail(type, idx)"/>
                </vs-td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        props: ["template", "type", "allowAction"],
        components: {
            //
        },
        mounted(){
            //
        },
        computed: {
			      //
        },
        watch: {
			      //
        },
        data: () => ({
            //
        }),
        methods: {
            removeTemplateDetail(type, idx) {
                this.$emit("removeTemplateDetail", {
                    type: type,
                    idx: idx,
                });
            }
		    }
    };
</script>
